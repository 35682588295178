import { brandPrimary } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';
import { actionItemTintColor } from './../../styles/colors';
import { TinyColor } from '@ctrl/tinycolor';
import { StyleSheet } from 'aphrodite';

const tinyColor = (color: string) => new TinyColor(color);

export const styleSheet = StyleSheet.create({
	container: {},
	ctaWithIcon: {
		':hover:not(:disabled)': {
			textDecoration: 'underline',
		},
		color: brandPrimary,
		fontSize: 14,
		paddingTop: 12,
		width: '100%',
		...CssSelectors.allDescendants(
			{
				height: 14,
				marginRight: 6,
			},
			'svg'
		),
		...CssSelectors.allChildren(
			{
				alignItems: 'center',
				display: 'flex',
			},
			'span'
		),
	},
	success: {
		':active': {
			background: tinyColor(actionItemTintColor)?.darken(8)?.toHexString(),
		},
		':hover': {
			background: tinyColor(actionItemTintColor)?.lighten(6)?.toHexString(),
		},
		background: actionItemTintColor,
	},
});
