import { StyleSheet } from 'aphrodite';

export const translateKeyframes = {
	'0%': {
		transform: 'translate3d(0, 0, 0)',
	},
	'100%': {
		transform: 'translate3d(0, -1px, 0)',
	},
	'50%': {
		transform: 'translate3d(0, 5px, 0)',
	},
};
export const translateLoadingKeyframes = {
	'0%': {
		transform: 'translate3d(0, 0, 0)',
	},
	'100%': {
		transform: 'translate3d(0, -1px, 0)',
	},
	'50%': {
		transform: 'translate3d(0, 5px, 0)',
	},
};

export const translateLoadedKeyframes = {
	'0%': {
		transform: 'translate3d(0, 10px, 0)',
	},
	'100%': {
		transform: 'translate3d(0, 0, 0)',
	},
};

export const styleSheet = StyleSheet.create({
	hovering: {
		// animationDelay: '1000ms',
		animationDuration: '2500ms',
		animationFillMode: 'alternate',
		animationIterationCount: 'infinite',
		animationName: [translateKeyframes],
		animationTimingFunction: 'ease-in-out',
	},
	loading: {
		animationDuration: '2500ms',
		animationFillMode: 'alternate',
		animationIterationCount: 'infinite',
		animationName: [translateLoadingKeyframes],
		animationTimingFunction: 'ease-in-out',
	},
	topLayer: {
		animationDuration: '1000ms',
		animationFillMode: 'alternate',
		animationIterationCount: 1,
		animationName: [translateLoadedKeyframes],
		animationTimingFunction: 'ease-in-out',
	},
});
