import { UserSessionContext } from '@ViewModels';
import invariant from 'tiny-invariant';
import {
	ActionItemComposerViewModelKey,
	AppState,
	EnvironmentKey,
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	NoteComposerViewModelKey,
	PushNotificationsViewModelKey,
	QuickAddEntityViewModelKey,
	SingleEmailComposerKey,
	TextMessagingViewModelKey,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../AppState';

export const useFullscreenModal = () => {
	const fullscreenModal = AppState[FullScreenModalViewModelKey];
	invariant(fullscreenModal != null, 'FullScreenModalViewModel is not initialized');
	return fullscreenModal;
};

export const useToaster = () => {
	const toaster = AppState[ToasterViewModelKey];
	invariant(toaster != null, 'ToasterViewModel is not initialized');
	return toaster;
};

export const useErrorMessages = () => {
	const errorMessages = AppState[ErrorMessagesViewModelKey];
	invariant(errorMessages != null, 'ErrorMessagesViewModel is not initialized');
	return errorMessages;
};

export const useEnvironment = () => {
	const environment = AppState[EnvironmentKey];
	invariant(environment != null, 'Environment is not initialized');
	return environment;
};

export const useNoteComposer = () => {
	const noteComposer = AppState[NoteComposerViewModelKey];
	invariant(noteComposer != null, 'NoteComposerViewModel is not initialized');
	return noteComposer;
};

export const useActionItemComposer = () => {
	const actionItemComposer = AppState[ActionItemComposerViewModelKey];
	invariant(actionItemComposer != null, 'ActionItemComposerViewModel is not initialized');
	return actionItemComposer;
};

export const useSingleEmailComposer = () => {
	const singleEmailComposer = AppState[SingleEmailComposerKey];
	invariant(singleEmailComposer != null, 'SingleEmailComposerViewModel is not initialized');
	return singleEmailComposer;
};

export const useTextMessaging = () => {
	const textMessaging = AppState[TextMessagingViewModelKey];
	invariant(textMessaging != null, 'TextMessagingViewModel is not initialized');
	return textMessaging;
};

export const useQuickAddEntity = () => {
	const quickAddEntity = AppState[QuickAddEntityViewModelKey];
	invariant(quickAddEntity != null, 'QuickAddEntityViewModel is not initialized');
	return quickAddEntity;
};

export const usePushNotifications = () => {
	const pushNotifications = AppState[PushNotificationsViewModelKey];
	invariant(pushNotifications != null, 'PushNotificationsViewModel is not initialized');
	return pushNotifications;
};

/** @requires observer */
export const useUserSession = <T extends UserSessionContext = UserSessionContext>() => {
	return AppState[UserSessionViewModelKey] as T;
};
