import * as React from 'react';
import LargeCheckmarkIconUrl from '../../assets/checkmark.svg';
import { brandSecondary } from '../../styles/colors';
import './styles.less';

export type IProps = React.AllHTMLAttributes<HTMLInputElement> & {
	backgroundFillColor?: string;
	checkboxClassName?: string;
	checked?: boolean;
	checkedBackgroundFillColor?: string;
	checkmarkFillColor?: string;
	childrenPosition?: 'left' | 'right';
	className?: string;
	onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
	onClick?: React.MouseEventHandler<HTMLElement>;
	id?: string;
	partial?: boolean;
	strokeColor?: string;
	type?: 'small' | 'large';
	readOnly?: boolean;
};

export const Checkbox = React.forwardRef<HTMLInputElement, IProps>(function _Checkbox(props, ref) {
	const {
		title,
		className,
		checkboxClassName,
		checkmarkFillColor,
		id,
		children,
		type,
		disabled,
		strokeColor,
		checkedBackgroundFillColor,
		backgroundFillColor,
		checked,
		readOnly,
		partial,
		childrenPosition,
		onChange,
		onClick,
		...restProps
	} = props;
	return (
		<label
			htmlFor={props.id}
			className={`checkbox${type === 'large' ? ' checkbox-large' : ''}${disabled ? ' disabled' : ''} ${
				props.className || ''
			}`}
			title={title}
			onClick={onClick}
		>
			<input
				{...restProps}
				checked={checked}
				className={`checkbox-input ${props.checkboxClassName || ''}`}
				disabled={disabled}
				id={props.id}
				onChange={onChange}
				type='checkbox'
				readOnly={readOnly}
				ref={ref}
			/>
			<div className={`checkbox-content ${disabled ? 'disabled' : ''}`}>
				{childrenPosition === 'left' && (children || null)}
				{type === 'large' ? (
					<img className='checkbox-large-checkmark absolute-center' src={LargeCheckmarkIconUrl} />
				) : (
					<svg width='14' height='14' viewBox='0 0 14 14'>
						<g fill='none' fillRule='evenodd'>
							<rect
								fill={checked ? checkedBackgroundFillColor || undefined : backgroundFillColor ?? undefined}
								height='13'
								rx='2'
								stroke={strokeColor || '#858585'}
								width='13'
								x='.5'
								y='.5'
							/>
							{partial ? (
								<path d='M3,7 L11,7' strokeWidth='1.5' stroke={brandSecondary} strokeLinecap='round' />
							) : (
								<path
									className='checkbox-check'
									fill={checkmarkFillColor || brandSecondary}
									d='M2.53633988,8.53633988 C2.24012754,8.24012754 2.23193359,7.76806641 2.52342987,7.47657013 L2.47657013,7.52342987 C2.76565246,7.23434754 3.23728013,7.23728013 3.52547646,7.52547646 L5,9 L10.46754,3.53245997 C10.7616095,3.23839045 11.2319336,3.23193359 11.5234299,3.52342987 L11.4765701,3.47657013 C11.7656525,3.76565246 11.7670975,4.23290253 11.4738623,4.52613771 L5,11 L2.53633988,8.53633988 Z'
								/>
							)}
						</g>
					</svg>
				)}
				{childrenPosition !== 'left' && (children || null)}
			</div>
		</label>
	);
});
