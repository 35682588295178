import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { Noop } from '../../../extViewmodels/Utils';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	accessory?: JSX.Element;
	className?: string;
	kind?: 'primary' | 'secondary' | 'success' | 'destructive' | 'reverse' | 'icon' | 'custom';
	label: string | JSX.Element;
	onClick?(e: React.MouseEvent<HTMLElement>): void;
	size?: 'normal' | 'small';
	styles?: StyleDeclarationValue[];
}

export const Button: React.FC<IProps> = ({
	accessory,
	label,
	kind: type = 'primary',
	size = 'normal',
	onClick,
	className,
	styles,
	disabled,
	...otherProps
}) => {
	let style = '';
	switch (type) {
		case 'primary':
			if (size === 'normal') {
				style = css(baseStyleSheet.ctaButton);
			} else if (size === 'small') {
				style = css(baseStyleSheet.ctaButtonSmall);
			}
			break;

		case 'secondary':
			if (size === 'normal') {
				style = css(baseStyleSheet.ctaButtonSecondary);
			}
			break;

		case 'success':
			if (size === 'normal') {
				style = css(baseStyleSheet.ctaButton, styleSheet.success);
			} else if (size === 'small') {
				style = css(baseStyleSheet.ctaButtonSmall, styleSheet.success);
			}
			break;

		case 'destructive':
			if (size === 'normal') {
				style = css(baseStyleSheet.ctaButtonDestructive);
			} else if (size === 'small') {
				style = css(baseStyleSheet.ctaButtonReverseSmall);
			}
			break;

		case 'reverse':
			if (size === 'normal') {
				style = css(baseStyleSheet.ctaButtonReverse);
			} else if (size === 'small') {
				style = css(baseStyleSheet.ctaButtonReverseSmall);
			}
			break;
		case 'icon':
			style = css(styleSheet.ctaWithIcon);
			break;
		default:
			break;
	}

	return (
		<button
			className={`${style} ${disabled && css(baseStyleSheet.disabled)} ${css(...(styles || []))} ${className || ''}`}
			onClick={disabled ? Noop : onClick}
			{...otherProps}
		>
			<span>
				{type === 'icon' && accessory}
				{label}
			</span>
		</button>
	);
};
